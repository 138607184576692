import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Departure } from "../../data/Departure";
import { getTextWidth } from "../../utilities/helpers";
import { INotificationMessages } from "../../components/travelCenter/notificationMessages/NotificationMessages";
import { RowColor } from "../../components/travelCenter/RowColor";
import TravelCenterBoardCompanionItem, { ICompanionTrain, CompanionItemType } from "../../components/travelCenter/boardItem/TravelCenterBoardCompanionItem";
import TravelCenterBoardItem from "../../components/travelCenter/boardItem/TravelCenterBoardItem";
import "./rightPanel.css";
import PaginationIndicator from "../PaginationIndicator";

type RightPanelPropsType = {
  connections: Departure[];
  indicator: number;
  maxPages: number;
};

const RightPanel: FunctionComponent<RightPanelPropsType> = (props) => {
  const PLATFORM_AREA_WIDTH = 170;

  const [departuresMap, setDeparturesMap] = useState<Map<number, any[]>>(new Map<number, any[]>());

  const carouselRef = useRef<HTMLDivElement>(null);

  const VISIBLE_ITEM_COUNT = 6;

  const itemsRef = useRef<HTMLDivElement[]>([]);
  const initialSetupRef = useRef<boolean>(true);

  const currentIndexRef = useRef<number>(0);
  const prevIndexRef = useRef<number>(0);
  const maxPageRef = useRef<number>(props.maxPages);

  useEffect(() => {
    if (initialSetupRef.current) {
      if (initialSetupRef.current === true) {
        initialSetupRef.current = false;
        return;
      }
    }

    if (carouselRef.current) {
      carouselRef.current.classList.add("sliding-transition");

      prevIndexRef.current = currentIndexRef.current;
      currentIndexRef.current = (currentIndexRef.current + 1) % maxPageRef.current;

      carouselRef.current.style.transform = `translateX(-1860px)`;

      setTimeout(() => {
        if (carouselRef.current && itemsRef.current) {
          carouselRef.current.appendChild(itemsRef.current[prevIndexRef.current]);
          carouselRef.current.classList.remove("sliding-transition");
          carouselRef.current.style.transform = "";
        }
      }, 1500);
    }
  }, [props.indicator]);

  useEffect(() => {
    if (props.connections.length === 0) {
      return;
    }

    var tempDeparturesMap = new Map<number, Departure[]>();

    for (let pageIndex = 0; pageIndex < props.maxPages; pageIndex++) {
      var maxPlatformLength = 0;

      const indexLimit = Math.min(VISIBLE_ITEM_COUNT * (pageIndex + 1), props.connections.length);

      for (let index = 0 + pageIndex * VISIBLE_ITEM_COUNT; index < indexLimit; index++) {
        const element = props.connections[index];
        const platformLength = getTextWidth(element.platform, "72px db-screen-sans-bold, sans-serif");

        if (platformLength > maxPlatformLength && index < indexLimit) {
          maxPlatformLength = platformLength;
        }
      }

      maxPlatformLength = Math.ceil(maxPlatformLength);

      var additionalPlatformOffset = 0;
      if (maxPlatformLength > PLATFORM_AREA_WIDTH) {
        additionalPlatformOffset = maxPlatformLength - PLATFORM_AREA_WIDTH;
      }

      var index = 0 + pageIndex * VISIBLE_ITEM_COUNT;

      let currentBackgroundSelector: RowColor = RowColor.white;

      var departureListItems: any[] = [];

      while (index < VISIBLE_ITEM_COUNT * (pageIndex + 1)) {
        const conn = props.connections[index];

        var notification: INotificationMessages = {
          isLandscape: true,
          prioType: conn.notificationPrioType,
          messages: conn.notificationMessages,
          backgroundColor: RowColor.white,
          isCompanion: false,
          isLufthansaTrain: conn.lufthansa !== undefined,
        };

        if (currentBackgroundSelector === RowColor.white) {
          currentBackgroundSelector = RowColor.gray;
        } else {
          currentBackgroundSelector = RowColor.white;
        }

        var isCompanionModeActive = false;
        var companionTrains: ICompanionTrain[] = [];
        var companionItemType: CompanionItemType = CompanionItemType.one_train_cut;
        var companionNotifications: INotificationMessages[] = [];

        if (conn.companions.length > 0) {
          isCompanionModeActive = true;

          const currentTrain: ICompanionTrain = {
            transportType: conn.type,
            transportCategory: "",
            transportNumber: conn.label,
            destinationName: conn.destination,
            lufthansaTrain: conn.lufthansa ?? "",
            differingDestinationName: conn.differingDestination,
            viaStations: conn.viaStops,
            canceled: conn.canceled,
          };

          companionTrains.push(currentTrain);

          for (let index = 0; index < conn.companions.length; index++) {
            const companionConnectionJourneyId = conn.companions[index];

            var companionTrainArray = props.connections.filter((item, index) => {
              return item.journeyID === companionConnectionJourneyId && index < indexLimit && index > pageIndex * VISIBLE_ITEM_COUNT;
            });

            if (companionTrainArray.length > 0) {
              const companionConnection = companionTrainArray[0];

              const companion: ICompanionTrain = {
                transportType: companionConnection.type,
                transportCategory: "",
                transportNumber: companionConnection.label,
                destinationName: companionConnection.destination,
                lufthansaTrain: companionConnection.lufthansa ?? "",
                differingDestinationName: companionConnection.differingDestination,
                viaStations: companionConnection.viaStops,
                canceled: companionConnection.canceled,
              };

              companionTrains.push(companion);
            }
          }

          const isFirstItem = index === 0 + pageIndex * VISIBLE_ITEM_COUNT;

          if (companionTrains.length === 1 && index === indexLimit - 1) {
            companionItemType = CompanionItemType.one_train_cut;
          } else if (companionTrains.length === 2 && conn.companions.length === 1) {
            companionItemType = CompanionItemType.two_trains_complete;
          } else if (companionTrains.length === 2 && conn.companions.length === 2 && index === indexLimit - 2) {
            companionItemType = CompanionItemType.two_trains_cut;
          } else if (companionTrains.length === 3 && conn.companions.length === 2) {
            companionItemType = CompanionItemType.three_trains_complete;
          } else if (isFirstItem && conn.companions.length !== 0 && companionTrains.length === 1) {
            companionItemType = CompanionItemType.one_train_last;
          } else if (isFirstItem && conn.companions.length !== 0 && companionTrains.length === 2) {
            companionItemType = CompanionItemType.two_trains_last;
          } else {
            isCompanionModeActive = false;
          }
        }

        if (isCompanionModeActive) {
          var secondNotification = JSON.parse(JSON.stringify(notification));

          companionNotifications.push(notification);

          if (companionItemType === CompanionItemType.three_trains_complete) {
            companionNotifications.push(secondNotification);
          }
        }

        var position = {
          newPosition: index % VISIBLE_ITEM_COUNT,
          oldPosition: index & VISIBLE_ITEM_COUNT,
        };

        if (isCompanionModeActive) {
          const companionItem = {
            key: conn.departureID,
            platform: conn.platform,
            time: conn.canceled ? "" : conn.time,
            timeSchedule: conn.timeSchedule,
            platformSchedule: conn.platformSchedule,
            currentBackgroundSelector: currentBackgroundSelector,
            companionTrains: companionTrains,
            position: position,
            notifications: companionNotifications,
            type: companionItemType,
            mode: "companion",
            additionalPlatformOffset: additionalPlatformOffset,
          };

          departureListItems.push(companionItem);

          var companionCount = conn.companions.length;
          if (companionItemType === CompanionItemType.one_train_last) {
            companionCount = 0;
          } else if (companionItemType === CompanionItemType.two_trains_last) {
            companionCount = 1;
          }

          index = index + companionCount + 1;
        } else {
          const departureListItem = {
            key: conn.departureID,
            time: conn.canceled ? "" : conn.time,
            timeSchedule: conn.timeSchedule,
            transportType: conn.type,
            transportCategory: "",
            transportNumber: conn.label,
            destinationName: conn.destination,
            viaStations: conn.viaStops,
            platform: conn.platform,
            platformSchedule: conn.platformSchedule,
            currentBackgroundSelector,
            lufthansaTrain: conn.lufthansa,
            differingDestination: conn.differingDestination,
            canceled: conn.canceled,
            notification: notification,
            position: position,
            mode: "single",
            additionalPlatformOffset: additionalPlatformOffset,
            replacedTrainNumber: conn.replacedTrainLabel,
          };
          departureListItems.push(departureListItem);

          index = index + 1;
        }
      }

      tempDeparturesMap.set(pageIndex, departureListItems);
    }

    setDeparturesMap(tempDeparturesMap);
  }, [props.connections, props.maxPages]);

  return (
    <div className="right-panel">
      <div className={"sub-header"}>
        <div className={props.indicator !== 0 ? "column-name time" : "column-name fade-out time"}>
          <div className="top">Weitere Fahrten</div>
          <div className="bottom">Further Departures</div>
        </div>

        <div className="column-name train">
          <div className="top">
            <PaginationIndicator currentItem={props.indicator} itemCount={props.maxPages} />
          </div>
        </div>
      </div>
      <div className={"sub-header-shadow"}></div>

      <div className="pages" ref={carouselRef}>
        {Array.from(departuresMap.entries()).map((element) => {
          return (
            <div
              className="travel-center-list"
              key={"page_" + element[0]}
              ref={(el) => {
                if (el) itemsRef.current[element[0]] = el;
              }}
            >
              {element[1].map((item) => {
                if (item.mode === "single") {
                  return (
                    <TravelCenterBoardItem
                      isVerticalAnimationAllowed={false}
                      isLandscape={true}
                      key={item.key}
                      time={item.time}
                      timeSchedule={item.timeSchedule}
                      currentBackgroundSelector={item.currentBackgroundSelector}
                      platform={item.platform}
                      platformSchedule={item.platformSchedule}
                      transportType={item.transportType}
                      transportCategory={item.transportCategory}
                      transportNumber={item.transportNumber}
                      destinationName={item.destinationName}
                      differingDestinationName={item.differingDestination?.name}
                      lufthansaTrain={item.lufthansaTrain}
                      viaStations={item.viaStations}
                      canceled={item.canceled}
                      notification={item.notification}
                      position={item.position}
                      additionalPlatformOffset={item.additionalPlatformOffset}
                      replacedTrainNumber={item.replacedTrainNumber}
                    ></TravelCenterBoardItem>
                  );
                } else {
                  return (
                    <TravelCenterBoardCompanionItem
                      isVerticalAnimationAllowed={false}
                      isLandscape={true}
                      key={item.key}
                      time={item.time}
                      timeSchedule={item.timeSchedule}
                      currentBackgroundSelector={item.currentBackgroundSelector}
                      platform={item.platform}
                      platformSchedule={item.platformSchedule}
                      companionTrains={item.companionTrains}
                      position={item.position}
                      notifications={item.notifications}
                      type={item.type}
                      additionalPlatformOffset={item.additionalPlatformOffset}
                    ></TravelCenterBoardCompanionItem>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RightPanel;
